
import Vue from 'vue'
import Shared from '@/shared.js'
export default Vue.extend({
  name: 'CompetitionPage',
  mixins: [Shared],
  data () {
    return {
      search: '',
      headers: [
        {
          text: this.$t('name'),
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('gender'),
          sortable: true,
          value: 'gender'
        },
        {
          text: this.$t('birthday'),
          sortable: true,
          value: 'birthday'
        },
        {
          text: this.$t('club'),
          sortable: true,
          value: 'current'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('PlayersModule/fetchAndAdd', { clauses: { limit: 0 } })
    this.$store.dispatch('ClubsModule/fetchAndAdd', { clauses: { limit: 0 } })
  },
  methods: {
    getClub (id: string) {
      if (this.$store.state.ClubsModule.data[id] !== undefined) {
        return this.$store.state.ClubsModule.data[id].name
      }
      return this.$t('no-club-assigned')
    }
  }
})
