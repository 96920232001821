import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"flex",attrs:{"fluid":""}},[_c(VRow,{staticClass:"mb-6",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"md":9,"sm":12}},[_c('div',{staticClass:"home px-5 pt-10"},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_c('h1',[_vm._v(_vm._s(_vm.$t('players')))])]),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.$store.getters['PlayersModule/getList'],"sort-by":['name'],"items-per-page":50,"search":_vm.search},scopedSlots:_vm._u([{key:`item.birthday`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.parseDate(item.birthday,'DD/MM/YYYY'))+" ")]}},{key:`item.current`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getClub(item.current))+" ")]}},{key:`item.gender`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(item.gender))+" ")]}}],null,true)})],1)]),_c(VCol,{staticClass:"pt-10 px-5 d-none d-sm-flex",attrs:{"md":3}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }